/* eslint-disable consistent-return */
import http from '../index';

const API_BASE = 'patient/gabineteTypeProcedure';
const API_BASE_EQUIPOS = 'patient/equipos';
const API_BASE_CATALOGO = 'patient/catalogGabineteAgenda';
const API_BASE_TIPO_CITA = 'patient/gabinete/all';

const API_GABINETE = {
  getAllEstudios: async () => {
    try {
      const request = await http.get(API_BASE);
      if (request) {
        return request.data.flat();
      }
      return [];
    } catch (error) {
      // console
    }
  },
  getAllEquipos: async () => {
    try {
      const request = await http.get(API_BASE_EQUIPOS);
      if (request) {
        const dataFlat = request.data.flat();
        const data = dataFlat.map((item) => {
          const newItem = item;
          newItem.label = item.name;
          newItem.value = item.id;

          return newItem;
        });
        localStorage.setItem('equipos', JSON.stringify(data));
        return;
      }
      return [];
    } catch (error) {
      // console
    }
  },
  getAllCatalogoGabineteAgenda: async () => {
    try {
      const request = await http.get(`${API_BASE_CATALOGO}/`);
      if (request) {
        const formatData = request.data.flat().map((item) => {
          const newItem = item;
          newItem.label = item.name;
          newItem.value = item.name;
          return newItem;
        });
        localStorage.setItem('gabineteAgenda', JSON.stringify(formatData));
        return request.data.flat();
      }
      return [];
    } catch (error) {
      // console
    }
  },
  getCatalogoGabineteAgenda: async (id) => {
    try {
      const request = await http.get(`${API_BASE_CATALOGO}/${id}`);
      if (request) {
        return request.data;
      }
      return [];
    } catch (error) {
      // console
    }
  },
  getTiposCita: async () => {
    try {
      const request = await http.get(`${API_BASE_TIPO_CITA}`);
      if (request) {
        const dataFlat = request.data.flat();

        const data = dataFlat.map((item) => {
          const newItem = item;

          newItem.value = item.id;
          newItem.label = item.name;

          return newItem;
        });

        localStorage.setItem('gabinete', JSON.stringify(data));
      }
    } catch (error) {
      // console
    }
  },
};

export default API_GABINETE;

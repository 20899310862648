// Dependencies
import Vue from 'vue';
import jsCookie from 'js-cookie';
import { Notification } from 'element-ui';
import axios from 'axios';
import { resetRouter } from '../../router';
import http, { baseURL } from '../../API';

// State
const initialState = {
  access: null,
  user: {},
};

// Mutations
const mutations = {
  login(state, { access, user }) {
    return Object.assign(state, { user, access });
  },
  logout(state) {
    return Object.assign(state, initialState);
  },
  update(state, current) {
    return Object.assign(state, current);
  },
  updateUser(state, payload) {
    return Object.assign(state.user, { ...payload });
  },
  refresh(state, access) {
    return Object.assign(state, { access });
  },
};

// Actions
const actions = {
  async login(ctx, { email, password }) {
    try {
      const response = await http.post('auth/signIn', {
        email,
        password,
      });
      const { data } = response;
      if (data) {
        const { refresh, access, user } = data;
        jsCookie.set('x-refresh-jwt', refresh);
        ctx.commit('login', { access, user });
        Notification({
          type: 'success',
          title: '¡Éxito!',
          message: 'Has iniciado sesión.',
        });
        return true;
      }
      Notification({
        type: 'warning',
        title: 'Error.',
        message: 'Algo salió mal. No se pudo iniciar sesión.',
      });
      return false;
    } catch (error) {
      Vue.config.errorHandler(error);
      return false;
    }
  },
  async logOut({ commit }) {
    try {
      jsCookie.remove('x-refresh-jwt');
      await http.post('auth/signout');
    } finally {
      commit('logout');
      resetRouter();
      Notification({
        type: 'success',
        title: 'Sesión cerrada',
        message: 'Se ha serrado sesión con éxito.',
      });
    }
  },
  async refreshToken({ commit }) {
    try {
      const response = await axios.post(`${baseURL}auth/refreshtoken`, {
        refresh: jsCookie.get('x-refresh-jwt'),
      }, {
        withCredentials: true,
      });
      const { data } = response;
      if (data?.access) {
        commit('refresh', data.access);
        return data.access;
      }
      jsCookie.remove('x-refresh-jwt');
      return false;
    } catch (error) {
      jsCookie.remove('x-refresh-jwt');
      Vue.config.errorHandler(error);
      return false;
    }
  },
  updateUser({ commit }, payload) {
    if (payload) {
      commit('updateUser', payload);
    }
  },
};

export default {
  state: initialState,
  mutations,
  actions,
  namespaced: true,
};

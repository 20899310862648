import Vue from 'vue';
import VueAxios from 'vue-axios';
import Vuex from 'vuex';
import { Notification, Message } from 'element-ui';
import Inputmask from 'inputmask';
import 'normalize.css/normalize.css';
import './styles/index.scss';
import './plugins/element';
import App from './App';
import router from './router';
import store from './store';
import http from './API';
import Avatar from './components/avatar';
import './permission';
import userErrorNotify from './utils/userErrorNotify';
import { ageAsWords, ageNumber } from './utils/ageAsWords';
import dateString from './utils/dateString';
import especialidades from './API/globales/especialidades';
import API_GABINETE from './API/globales/estudios';

Vue.directive('mask', {
  bind(el, binding) {
    Inputmask(binding.value).mask(el.getElementsByTagName('INPUT')[0]);
  },
});
Vue.prototype.$errorNotify = userErrorNotify;
Vue.prototype.$ageAsWords = ageAsWords;
Vue.prototype.$ageNumber = ageNumber;
Vue.prototype.$dateString = dateString;
Vue.prototype.$notify = (opt) => {
  Notification.closeAll();
  Notification(opt);
};
Vue.prototype.$message = (opt) => {
  Message.closeAll();
  Message(opt);
};
Vue.prototype.$capitalize = (val) => val.replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase());
Vue.config.errorHandler = () => {};
Vue.component('Avatar', Avatar);
Vue.use(Vuex);

especialidades.getEspecialidades();
API_GABINETE.getAllCatalogoGabineteAgenda();
API_GABINETE.getAllEquipos();
API_GABINETE.getTiposCita();

Vue.use(VueAxios, http);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

const appointmentsIo = ({
  state: {
    appointments: [],
    appointment: null,
  },
  mutations: {
    addAppointment(state, { appointment, socket }) {
      state.appointment = appointment;
      socket.emit('increment', state.appointment);
    },
    addAppointments(state, { appointment }) {
      state.appointments = [];
      state.appointments.push(...appointment);
    },
    addAppointmentsWithoutArray(state, { appointment }) {
      state.appointments.push(appointment);
    },
    refreshAppointments(state, { socket }) {
      socket.emit('refreshAppointments');
    },
    deleteAppointment(state, { id }) {
      const index = state.appointments.findIndex((appointment) => appointment.id === id);
      state.appointments.splice(index, 1);
    },
    updateAppointment(state, { appointment }) {
      const index = state.appointments.findIndex((app) => app.id === appointment.id);
      state.appointments.splice(index, 1, appointment);
    },
  },
  actions: {
    addAppointmentAction({ commit }, { appointment, socket }) {
      commit('addAppointment', { appointment, socket });
    },
    addAppointments({ commit }, appointment) {
      if (Array.isArray(appointment) === true) {
        commit('addAppointments', { appointment });
      } else {
        commit('addAppointmentsWithoutArray', { appointment });
      }
    },
    refreshAppointments({ commit }, socket) {
      commit('refreshAppointments', { socket });
    },
    setStateAppointment({ commit }, appointment) {
      commit('addAppointmentsWithoutArray', { appointment });
    },
    delAppointment({ commit }, id) {
      commit('deleteAppointment', { id });
    },
    updateAppointment({ commit }, appointment) {
      commit('addAppointmentsWithoutArray', { appointment });
    },
  },
  getters: {
    appointments(state) {
      return state.appointments;
    },
  },
});

export default appointmentsIo;

// Dependencies
const moment = require('moment');

const dateString = (date) => {
  try {
    const initialFormat = 'YYYY-MM-DD';
    const now = moment(date, initialFormat);
    return now.lang('es').format('dddd, DD [de] MMMM [de] YYYY').toUpperCase();
  } catch {
    return 'N/A';
  }
};

module.exports = dateString;

import http from '../index';

const BASEURI = 'patient/specialty';

const especialidades = {
  // eslint-disable-next-line consistent-return
  async getEspecialidades() {
    try {
      const request = await http.get(`${BASEURI}/all/v2`);
      if (request) {
        localStorage.setItem('especialidades', JSON.stringify(request.data));
        return request.data;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // eslint-disable-next-line consistent-return
  async getHoursEspecialty(year, month) {
    try {
      const request = await http.get(`${BASEURI}/v2/${year}/${month}`);
      if (request) return request.data;
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // eslint-disable-next-line consistent-return
  async postEspecialidad(payload) {
    try {
      const { patientId } = payload;
      const request = await http.post(`${BASEURI}/especialidad/${patientId}`, payload);
      if (request) return request.data;
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // eslint-disable-next-line consistent-return
  async postConsultaGeneral(payload) {
    try {
      const { patientId } = payload;
      const request = await http.post(`${BASEURI}/consultaGeneral/${patientId}`, payload);
      if (request) return request.data;
    } catch (error) {
      this.$errorNotify(error);
    }
  },
};

export default especialidades;

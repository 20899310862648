/**
 * Vuex main config file
 */
// Dependencies
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { getField, updateField } from 'vuex-map-fields';
import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  state: {
    controlValue: {
      showLayout: false,
      floatLayout: true,
      enableDownload: true,
      previewModal: true,
      paginateElementsByHeight: 1100,
      manualPagination: false,
      filename: 'Hee Hee',
      pdfQuality: 2,
      pdfFormat: 'a4',
      pdfOrientation: 'portrait',
      pdfContentWidth: '800px',
    },
  },
  mutations: {
    updateField,
  },
  getters: {
    getField,
  },
  plugins: [
    createPersistedState({
      paths: ['auth.user', 'app.loginDate'],
    }),
  ],
});

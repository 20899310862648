<template>
  <div id="app">
    <router-view />
    <!-- <el-dialog
      title="RENOVAR SESIÓN"
      :visible="visible"
      :show-close="false"
      :lock-scroll="false"
    >
      <h2>Su sesión ha caducado</h2>
      <p>Ingrese su contraseña para evitar salir del sitio o presione cerrar para salir.</p>
       <el-alert
        title="NOTA"
        type="info">
          Es posible que necesite recargar la página en algunas situaciones
          para que la aplicación vuelva a cargar los recursos.
        </el-alert>
      <el-form
        ref="relogin-form"
        :model="form"
        @submit.prevent.native
        @keyup.enter.native="beforeSubmit"
        :rules="rules"
        :disabled="loading || disabled"
        style="border: 1px solid #eee; padding: 15px"
      >
        <el-form-item label="Email" >
          <el-input disabled :value="email"/>
        </el-form-item>
        <el-form-item label="password" prop="password" :error="error">
          <el-input type="password" :disabled="loading" v-model="form.password"/>
        </el-form-item>
        <el-form-item>
          <el-button
            type="default"
            style="float:right"
            :disabled="loading"
            @click.native="close"
          >
            Salir
          </el-button>
          <el-button
            style="float:right;margin-right:15px"
            native-type="submit"
            type="primary"
            :disabled="loading"
            :loading="loading"
            @click.native="beforeSubmit"
          >
            Renovar Sesión
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'app',
  data() {
    return {
      loading: false,
      disabled: false,
      error: null,
      form: {
        password: '',
      },
      rules: {
        password: [
          {
            required: true,
            message: 'El campo no puede estar vacío.',
            trigger: 'blur',
          },
          {
            min: 6,
            message: 'Longitud mínima de 6 caracteres',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      email: (state) => state?.auth?.user?.email,
      visible: (state) => state?.app?.renewSessionFormVisibility,
    }),
  },
  methods: {
    beforeSubmit() {
      this.$refs['relogin-form'].validate((isValid) => {
        if (isValid) {
          this.relogin();
        }
      });
    },
    async relogin() {
      try {
        this.loading = true;
        this.error = null;
        const loggedIn = await this.$store.dispatch('auth/login', { email: this.email, password: this.form.password });
        if (loggedIn) {
          this.disabled = true;
          this.$store.dispatch('app/setRenewSessionFormVisibility', false);
        } else {
          this.error = 'Credenciales incorrectas.';
        }
      } catch {
        this.loading = false;
      } finally {
        this.loading = false;
        this.disabled = false;
        this.error = null;
        this.form.password = null;
      }
    },
    close() {
      this.$store.dispatch('app/setRenewSessionFormVisibility', false);
      this.$store.dispatch('auth/logOut');
      // eslint-disable-next-line
      location.reload();
    },
  },
};
</script>
